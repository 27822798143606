<template>
  <b-card>
    <b-row>
      <b-col>
        <b-alert
          class="mb-2 p-1"
          show
          align="center"
          variant="danger"
        >
          Why have the codes not arrived
          <b-button
            v-b-modal.modal-center
            class="more_information"
            variant="outline"
          >
            <b-icon
              icon="question-circle-fill"
            />
          </b-button>
          <b-modal
            id="modal-center"
            centered
            hide-footer
          >
            <p
                class="my-1"
            >
                1. You incorrectly entered your email or phone number during registration.<br>
            </p>
            <p
                class="my-1"
            >
                2. There are problems with your email account.<br>
            </p>
            <p
                class="my-1"
            >
                3. The verification email is in your spam folder<br>
            </p>
          </b-modal>
        </b-alert>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h3>
          <span class="step_style">1.</span> Check your email inbox and phone sms<br>
          <span class="step_style">2.</span> Enter your code from email and phone message<br>
          <span class="step_style">3.</span> Click confirm
        </h3>
      </b-col>
    </b-row>

    <b-row class="text-center">
      <b-col sm="12" md="6">
        <label for="phone_conf">Your Phone:</label>
        <b-form-input
          v-model="generalData.phone"
          readonly
          id="phone_conf"
          type="text"
        />
      </b-col>
      <b-col sm="12" md="3">
        <label for="phone_code">Code:</label>
        <b-form-input
          v-model="verifyCodePhone"
          id="phone_code"
          type="text"
        />
      </b-col>
      <b-col class="mt-2" sm="12" md="2">
        <b-button variant="warning" @click="resend_verify_code_phone">Resend</b-button>
      </b-col>
    </b-row>

    <b-row class="mt-2 text-center">
      <b-col sm="12" md="6">
        <label for="email_conf">Your Email:</label>
        <b-form-input
          v-model="generalData.email"
          readonly
          id="email_conf"
          type="text"
        />
      </b-col>
      <b-col sm="12" md="3">
        <label for="email_code">Code:</label>
        <b-form-input
          v-model="verifyCodeEmail"
          id="email_code"
          type="text"
        />
      </b-col>
      <b-col class="mt-2" sm="12" md="2">
        <b-button id="resend_email_code" @click="resend_verify_code_email" variant="warning">Resend</b-button>
      </b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col>
        <b-button
          variant="primary"
          @click="confirm"
        >
          Confirm
        </b-button>
      </b-col>
      <!-- <b-col>
        <b-button
          variant="secondary"
          @click="logout"
        >
          Log out
        </b-button>
      </b-col> -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
        verifyCodeEmail: '',
        verifyCodePhone: '',
        userData: JSON.parse(localStorage.getItem('userData')),
    }
  },

  beforeCreate() {
    // ? console.log('data')
    this.$http.get('/api/profile/get_instructor_info')
      .then(res => {
        /*
        const data = JSON.parse(res.data)
        console.log(data)
        */
        this.userInfo = res.data
      })
  },

  methods: {
    resend_verify_code_email() {
      this.$http.post('/api/authenticate/resend_verify_code_email')
        .then(response => {
          if (response.status === 200) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'CompassIcon',
                variant: 'success',
                text: 'The Email message has been sent successfully',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'CompassIcon',
                variant: 'danger',
                text: 'The user already exists',
              },
            })
          }
        })
    },
    resend_verify_code_phone() {
      this.$http.post('/api/authenticate/resend_verify_code_phone')
        .then(response => {
          if (response.status === 200) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'CompassIcon',
                variant: 'success',
                text: 'The Sms message has been sent successfully',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'CompassIcon',
                variant: 'danger',
                text: 'The user already exists',
              },
            })
          }
        })
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },

    confirm() {
      // console.log(JSON.stringify(this.verifyCode))
      // console.log(localStorage.getItem('userData'))
      this.$http.post('/api/profile/confirm_registration', {
        verify_code_email: this.verifyCodeEmail,
        verify_code_phone: this.verifyCodePhone,
      }).then(response => {
        if (response.data.userData && response.status === 200) {
          this.userData = response.data.userData
          // console.log(JSON.stringify(this.userData))
          // console.log(JSON.stringify(response.data))
          localStorage.setItem('userData', JSON.stringify(response.data.userData))
          this.$ability.update(response.data.userData.ability)
          this.$router.push('/')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'CompassIcon',
              variant: 'success',
              text: 'The Email message has been sent successfully',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CompassIcon',
              variant: 'danger',
              text: 'Email or SMS code entered incorrectly or missing',
            },
          })
        }
      })
    },
  },
}
</script>
<style>
  .alert-confirm-phone{
    margin-top: 5px;
    padding: 10px;
  }
</style>
<style lang="scss">
  .step_style{
    color: #012967;
    font-weight: 600
  }
  hr {
    border: 0;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
  }
</style>
