<template>
  <div>
    <b-progress
      v-if="this.conf.confirmed_phone || this.conf.confirmed_email"
      class="mb-2"
      height="1.5rem"
      variant="success"
      :max="progress_total"
    >
      <b-progress-bar
        :value="progress"
        :label="`${((progress / progress_total) * 100).toFixed(0)}%`"
      />
    </b-progress>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
      v-model="tabIndex"
    >

      <!-- general tab -->
      <b-tab active>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">General</span>
        </template>

        <account-setting-general
          v-if="data"
          :general-data="data"
        />
      </b-tab>
      <!--/ general tab -->

      <!-- Address -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon
            icon="BellIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Address</span>
        </template>

        <account-setting-address
          v-if="data"
          :address-data="data"
        />
      </b-tab>

      <b-tab>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Change Password</span>
        </template>

        <account-setting-password />
      </b-tab>
      <!--/ change password tab -->

      <b-tab active  v-if="!this.conf.confirmed_phone || !this.conf.confirmed_email">
        <!-- title -->
        <template #title>
          <b-icon 
            icon="check2-square"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Confirm phone and email</span>
        </template>

         <account-setting-confirm
          v-if="data"
          :general-data="data"
        />
      </b-tab>

      <b-button
        v-if="this.tabIndex !== 2 & this.tabIndex !== 3"
        variant="primary"
        class="mt-0 mr-1"
        @click="save_account_settings"
      >
        Save changes
      </b-button>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab, BProgress, BProgressBar, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
// import AccountSettingInformation from './AccountSettingInformation.vue'
// import AccountSettingSocial from './AccountSettingSocial.vue'
import AccountSettingAddress from './AccountSettingAddress.vue'
import AccountSettingConfirm from './AccountSettingConfirm.vue'
/* import AccountSettingPayment from './AccountSettingPayment.vue' */

export default {
  components: {
    BProgress,
    BProgressBar,
    BTabs,
    BTab,
    BButton,
    AccountSettingGeneral,
    AccountSettingPassword,
    // AccountSettingInformation,
    // AccountSettingSocial,
    AccountSettingAddress,
    AccountSettingConfirm,
    /* AccountSettingPayment, */
  },
  
  data() {
    return {
      tabIndex: '',
      conf: [],
      data: {},
      originalEmail: '',
      originalPhone: '',
      controlledFileds: [
        'first_name',
        'last_name',
        'phone',
        'zipcode',
        'city',
        'state',
        'country',
        'address_street',
        'address_line'
      ],
    }
  },
  computed: {
    progress_total() {
      return this.controlledFileds.length
    },

    progress() {
      const full = Object.entries(this.data)
        .map(([key, value]) => ({ key, value }))
      // const fields = Object.keys(this.options.general).map(key => ({ key, value: this.options.general[key] }))
      // .concat(Object.entries(this.options.address))
      // .concat(Object.entries(this.options.information))
      // .concat(Object.entries(this.options.social))
      // .filter(el => el.id === true)
      let total = 0

      // var filtered = object.main
      // console.log(JSON.stringify(full))
      full.forEach(field => {
        if (this.controlledFileds.includes(field.key) && field.value) {
          // console.log(JSON.stringify(field))
          total += 1
        }
      })
      return total
    },
  },
  created() {
    this.data_refresh()
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    data_refresh() {
      this.conf = JSON.parse(localStorage.getItem('userData'))

      this.$http.get('/api/profile/get_account_settings', {})
        .then(res => {
          // const data = JSON.parse(res.data)
          // console.log(res.data)
          this.data = res.data
          this.originalEmail = res.data.email
          this.originalPhone = res.data.phone
        })
    },
    save_account_settings() {
      this.$http.post('/api/profile/save_account_settings', this.data)
        .then(response => {
          if (response.data.logout && (this.originalEmail !== this.data.email)) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'CompassIcon',
                variant: 'danger',
                text: 'Your number has been changed you need to confirm again',
              },
            })

            this.logout()
            return
          }
          this.data_refresh()

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'CompassIcon',
              variant: 'success',
              text: 'Saved',
            },
          })
        })
    },
  },
  /*
  beforeCreate() {
    this.$http.get('/account-setting/data')
      .then(res => {
        this.options = res.data
      })
  },
  */
}
</script>

<style lang="scss">
  .custom-hide{
    display: none;
  }
</style>
