<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-0">
            <feather-icon
              icon="BellIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              Address
            </h4>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <!-- website -->
        <b-col md="6">
          <b-form-group
            label-for="city"
            label="City"
          >
            <b-form-input
              id="city"
              v-model="addressData.city"
              placeholder="City"
            />
          </b-form-group>
        </b-col>
        <!-- birth date -->
        <b-col md="6">
          <b-form-group
            label-for="State"
            label="State"
          >
            <b-form-input
              id="state"
              v-model="addressData.state"
              placeholder="State"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Postal Code"
            label-for="zip"
          >
            <b-form-input
              id="zip"
              v-model="addressData.zipcode"
              placeholder="Zip"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="countryList"
            label="Country"
          >
            <v-select
              id="countryList"
              v-model="addressData.country"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="countryOption"
            />
          </b-form-group>
        </b-col>
        <!--/ Country -->

        <!-- phone -->
        <b-col md="6">
          <b-form-group
            label-for="addressstreet"
            label="Address Line 1"
          >
            <b-form-input
              id="addressstreet"
              v-model="addressData.address_street"
              placeholder="Address Street"
            />
          </b-form-group>
        </b-col>
        <!-- phone -->

        <!-- phone -->
        <b-col md="6">
          <b-form-group
            label-for="addressline"
            label="Address Line 2"
          >
            <b-form-input
              id="addressline"
              v-model="addressData.address_line"
              placeholder="Address Line"
            />
          </b-form-group>
        </b-col>
        <!-- phone -->

        <!-- phone -->
        <!-- <b-col md="12">
          <b-form-group
            label-for="parks"
            label="Parks"
          >
            <b-form-textarea
              id="parks"
              v-model="addressData.parks"
              placeholder="Parks"
              rows="3"
              class="form-control"
            />
          </b-form-group>
          <b-form-group
            label-for="parks"
            label="Parks"
          >
            <multiselect
              id="parks"
              v-model="addressData.parks"
              tag-placeholder="Add this as new tag"
              placeholder="Search or add a skatepark"
              label="name"
              track-by="name"
              :options="options"
              :multiple="true"
              :taggable="true"
              @tag="addTag"
            />
          </b-form-group> -->
        <!-- </b-col> -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  // BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
// import Multiselect from 'vue-multiselect'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    // BFormTextarea,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    addressData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      countryOption: ['USA', 'Canada'],
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
      value: [
        { name: 'Javascript', code: 'js' },
      ],
      options: [
        { name: 'Vue.js', code: 'vu' },
        { name: 'Javascript', code: 'js' },
        { name: 'Open Source', code: 'os' },
      ],
    }
  },
  methods: {
    save_changes() {
      this.$http.post('/api/profile/save_address', this.addressData)
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      }
      this.options.push(tag)
      this.value.push(tag)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

hr {
  border: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
